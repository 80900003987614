.svg-animation-container{
    width: 400px;
    height: 150px;
    position: absolute;
    top: 50%;
    display: flex;
    justify-content: center;
    left: 50%;
    transform: translate(-50%,-50%);
}
.cls-1{
    fill:none;
    stroke:none;
    stroke-miterlimit:10;
    stroke-width:0.5px;
    fill-rule:evenodd;
}
#main-svg{
    height: 150px;
    width: 150px;
    position: absolute;
}
#main-svg #Main .cls-1{
    stroke:rgba(255, 215, 0, 1);
    stroke-dasharray: 159px;
    stroke-dashoffset: 159px;
    animation: main-animate 2.5s 1.9s forwards, main-animate-breath 2s 2.7s infinite;
}

#r-svg{
    height: 150px;
    width: 150px;
    margin-left: -30%;
    position: absolute;
    animation: r-animate 1.2s forwards;
    stroke-dasharray: 132px;
    stroke-dashoffset: 132px;
}
#r-svg #R .cls-1{
    stroke:#000;
}

#s-svg{
    height: 150px;
    width: 150px;
    position: absolute;
    stroke-dasharray: 102px;
    stroke-dashoffset: 0px;
    animation: s-animate 1.2s 1s forwards;
}
#s-svg #S .cls-1{
    stroke:#000;
}

#k-svg{
    height: 150px;
    width: 150px;
    margin-left: 45%;
    position: absolute;
    animation: k-animate 1.5s forwards;
    stroke-dasharray: 143px;
    stroke-dashoffset: 0px;
}
#k-svg #K .cls-1{
    stroke:#000;
}

@keyframes k-animate {
     0%{
         transform: rotateZ(0deg);
         stroke-dashoffset: 0px;
     }
     25%{
         transform: rotateZ(45deg);
         margin-left: 45%;
         stroke-dashoffset: 0px;
     }
     45%{
         transform: rotateZ(-360deg);
         margin-left: 0;
     }
    100%{
        transform: rotateZ(-360deg);
        stroke-dashoffset: 143px;
        margin-left: 0;
    }
 }

@keyframes r-animate {
    0%{
        transform: rotateZ(0deg);
        stroke-dashoffset: 0px;
    }
    25%{
        transform: rotateZ(-45deg);
        margin-left: -30%;
        stroke-dashoffset: 0px;
    }
    45%{
        stroke-dashoffset: 0px;
        transform: rotateZ(360deg);
        margin-left: 0;
    }
    100%{
        transform: rotateZ(360deg);
        stroke-dashoffset: 132px;
        margin-left: 0;
    }
}

@keyframes s-animate {
    0%{
        stroke-dashoffset: 0px;
    }
    100%{
        stroke-dashoffset: 102px;
    }
}

@keyframes main-animate {
    0%{
        fill: transparent;
        stroke-dashoffset: 159px;
    }
    25%{
        fill:transparent;
        stroke-dashoffset: 0px;
    }
    100%{
        fill:transparent;
        stroke-dashoffset: 0px;
    }
}
@keyframes main-animate-breath {
    0%{
        fill: rgba(255, 215, 0, 0.25);
    }
    50%{
        fill: rgba(255, 215, 0, 1);
    }
    100%{
        fill: rgba(255, 215, 0, 0.25);
    }
}
